<template>
  <div :class="clientWidth>mobileWidth?'system-content':'mobile-system-content'">
    <div class="left-content">
      <div class="mobile-title">
        <img src="../../../assets/imgs/index/icon_guanli@2x.png"/>
        <p class="title">广烁电子印章应用管理平台</p>
      </div>
      <p class="desc">致力于东方印章文化的传承与创新，提供全方位、多维度的电子印章申请、应用、管理等服务。</p>
      <div class="btn" @click="toHome" v-if="clientWidth>mobileWidth">马上体验</div>
    </div>
    <img src="../../../assets/imgs/index/pic_guanli@2x.png" class="peitu"/>
    <div class="btn" @click="toHome" v-if="clientWidth<=mobileWidth">马上体验</div>
  </div>
</template>

<script>
export default {
  name: 'System',
  data() {
    return {};
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    toHome() {
      this.$router.push('/eseal');
    },
  },
};

</script>

<style lang="less" scoped>
.system-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background: #F5F5F5;

  .left-content {
    margin-right: 130px;

    img {
      width: 62px;
      height: 58px;
    }

    .title {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin: 31px 0 16px;
    }

    .desc {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 30px;
      width: 352px;
    }

    .btn {
      width: 200px;
      background: #F75959;
      border-radius: 30px;
      padding: 15px 0;
      color: #fff;
      text-align: center;
      cursor: pointer;
      font-size: 21px;
    }
  }

  .peitu {
    width: 532px;
    height: 562px;
    display: block;
    opacity: 0;
  }
}

.mobile-system-content {
  background: #F3F3F3;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .mobile-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;

    img {
      width: 16px;
      height: 15px;
    }

    p {
      margin: 0 0 0 8px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
  }

  .desc {
    padding: 0 50px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.45);
    line-height: 17px;
  }

  .peitu {
    width: 233px;
    height: 245px;
    display: block;
    margin: 60px auto 0;
    opacity: 0;
  }

  .btn {
    width: 128px;
    height: 36px;
    background: #F75959;
    border-radius: 23px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
    margin: 60px auto 0;
  }
}
</style>
