<template>
  <div :class="clientWidth>mobileWidth?'keqi-content':'mobile-keqi-content'">
    <img src="../../../assets/imgs/index/pic_keqi@2x.png"
         class="peitu"
         v-if="clientWidth>mobileWidth"
    />
    <div class="desc-content">
      <div class="title-wapper">
        <img src="../../../assets/imgs/index/icon_keqi@2x.png"/>
        <p class="title">广烁刻企服务平台</p>
      </div>
      <p class="desc">广烁科技致力于为印章刻制企业提供优质服务。</p>
      <img src="../../../assets/imgs/index/pic_keqi@2x.png"
           class="peitu"
           v-if="clientWidth<=mobileWidth"
      />
      <div class="btn" @click="toDetail">点击前往</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'System',
  data() {
    return {};
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    toDetail() {
      window.open('https://kq.lngsyz.com', '_black');
    },
  },
};

</script>

<style lang="less" scoped>
.keqi-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  .desc-content {
    .title-wapper {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      img {
        width: 56px;
        height: 55px;
      }

      .title {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        margin: 0 0 0 24px;
      }
    }

    .desc {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 30px;
      margin-bottom: 25px;
    }

    .btn {
      width: 200px;
      background: #F75959;
      border-radius: 30px;
      padding: 15px 0;
      color: #fff;
      text-align: center;
      font-size: 21px;
      cursor: pointer;
    }
  }

  .peitu {
    width: 532px;
    height: 562px;
    display: block;
    margin-right: 141px;
    opacity: 0;
  }
}

.mobile-keqi-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  .peitu {
    width: 215px;
    height: 245px;
    display: block;
    margin: 40px auto 0;
    opacity: 0;
  }

  .title-wapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;

    img {
      width: 16px;
      height: 16px;
    }

    p {
      margin: 0 0 0 8px;
    }
  }

  .desc {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 0.45);
    line-height: 17px;
    text-align: center;
  }

  .btn {
    width: 128px;
    background: #F75959;
    border-radius: 23px;
    padding: 8px 0;
    color: #fff;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    margin: 40px auto 0;
  }
}
</style>
