<template>
  <div class="bg-info">
    <img src="../../../assets/imgs/index/company_name@2x.png"
         class="sys-title"
         v-if="clientWidth>mobileWidth"
    />
    <img src="../../../assets/imgs/index/index-title@2x.png"
         class="sys-title mobile-title"
         v-else
    />
    <p class="desc">中华文明源远流长，印章是贯穿中华历史长河的特有文化标记，广烁科技致力于东方印章文化的传承与创新，全部知识产权自主研发，签章产品安全更可信。</p>
  </div>
</template>

<script>
export default {
  name: 'BriefDesc',
  data() {
    return {};
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
};

</script>

<style lang="less" scoped>
.bg-info {
  background-image: url("../../../assets/imgs/index/bg_head1＠2x.png");
  background-size: 100% 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .sys-title {
    width: 637px;
    height: 56px;
    display: block;
  }

  .desc {
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    width: 952px;
    margin: 40px auto 0;
    text-align: center;
  }

  .mobile-title {
    width: 277px;
    height: 27px;
  }
}
</style>
