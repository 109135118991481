<template>
  <div :class="['product-center',clientWidth>mobileWidth?'':'mobile-product-center']">
    <p class="title">印章文化产品中心</p>
    <div class="item-content first-item-content">
      <div class="item" @click="downLoad(1)">
        <div class="image-wapper">
          <img src="../../../assets/imgs/index/icon_kehuduan@2x.png">
        </div>
        <p>广烁电子印章客户端</p>
      </div>
      <div class="item" @click="downLoad(4)">
        <div class="image-wapper">
          <img src="../../../assets/imgs/index/icon_shuomingshu@2x.png">
        </div>
        <p class="long-p">广烁电子印章签章客户端V2.0（使用说明书）</p>
      </div>
      <div class="item" @click="downLoad(2)">
        <div class="image-wapper">
          <img src="../../../assets/imgs/index/icon_zhongjianjian@2x.png">
        </div>
        <p>广烁电子印章中间件</p>
      </div>
    </div>
    <div class="item-content last-item-content">
<!--      <div class="item" @click="downLoad(3)">-->
<!--        <div class="image-wapper">-->
<!--          <img src="../../../assets/imgs/index/icon_paiban@2x.png">-->
<!--        </div>-->
<!--        <p>广烁印章排版系统</p>-->
<!--      </div>-->
      <div class="item" @click="toSealMaterial">
        <div class="image-wapper">
          <img src="../../../assets/imgs/index/icon_cailiao@2x.png">
        </div>
        <p>印章材料</p>
      </div>
      <div class="item" @click="toSealingEquipment">
        <div class="image-wapper">
          <img src="../../../assets/imgs/index/icon_shebei@2x.png">
        </div>
        <p>印章设备</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Products',
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  data() {
    return {
      links: [
        '/app/广烁电子签章系统(客户端)V2.0.1.19_政企通用互联网版(20240529).msi',
        '/app/广烁电子签章系统(中间件)V2.0.1.14_印章制作企业专用(20231218)_正式版.msi',
        '/app/广烁印章排版系统V1.5.msi',
        '/app/sealclienthelp_v2.0.pdf',
      ],
    };
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    downLoad(index) {
      window.open(this.links[index - 1]);
    },
    toSealMaterial() {
      this.$router.push('/Product/SealMaterial');
    },
    toSealingEquipment() {
      this.$router.push('/Product/SealingEquipment');
    },
  },
};
</script>

<style lang="less" scoped>
p {
  margin: 0;
}

.product-center {
  background: #F5F5F5;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    font-size: 36px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    position: relative;
    padding: 30px 0 50px;
    opacity: 0;

    &::after {
      position: absolute;
      content: "";
      width: 120px;
      height: 8px;
      background: #F75959;
      border-radius: 4px;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .item-content {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;

    .item {
      width: 268px;
      height: 268px;
      background: #FFFFFF;
      box-shadow: 0 10px 20px 0 rgba(153, 153, 153, 0.4);
      margin-right: 80px;
      position: relative;
      cursor: pointer;

      .image-wapper {
        width: 121px;
        height: 108px;
        line-height: 108px;
        margin: 60px auto 0;
        text-align: center;

        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          margin: 0 auto;
        }
      }

      p {
        position: absolute;
        width: 100%;
        bottom: 50px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
      }

      .long-p {
        bottom: 30px;
      }
    }

    .item:last-child {
      margin: 0;
    }
  }
}

.mobile-product-center {
  justify-content: center;

  .title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    padding-bottom: 30px;

    &::after {
      height: 4px;
      width: 60px;
      bottom: 25px;
    }
  }

  .item-content {
    display: block;
    margin: 0;

    .item {
      display: flex;
      margin: 0 38px 10px;
      width: calc(~'100% - 76px');
      height: 88px;
      background: #FFFFFF;
      box-shadow: 0px 5px 10px 0px rgba(153, 153, 153, 0.4);
      padding-left: 36px;
      align-items: center;
      opacity: 0;

      .image-wapper {
        margin: 0;
        width: 48px;
        height: 48px;
        line-height: 48px;
      }

      p {
        position: static;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 22px;
        text-align: left;
        padding-left: 29px;
      }
    }

    .item:last-child {
      margin: 0 38px 10px;
    }
  }

  .last-item-content {
    padding-bottom: 110px;
  }
}
</style>
