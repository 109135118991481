<template>
  <div class="index-container no-fullpage">
    <common-header
        :is-show-menu="true"
        :client-width="clientWidth"
        @openMobileMenu="openMobileMenu"
    />
    <!-- <full-page ref="fullpage" :options="options" id="fullpage"> -->
    <div class="section section1">
      <brief-desc :clientWidth="clientWidth"/>
    </div>
    <div class="section section2">
      <solution :clientWidth="clientWidth"/>
    </div>
    <div class="section section3">
      <system :clientWidth="clientWidth"/>
    </div>
    <div class="section section4">
      <keqi :clientWidth="clientWidth"/>
    </div>
    <div class="section section5">
      <products :clientWidth="clientWidth" @toApply="handleToApply"/>
    </div>
<!--    <div class="section section6">-->
<!--      <apply-use :clientWidth="clientWidth"/>-->
<!--    </div>-->
    <div class="section fp-auto-height">
      <mobile-footer v-show="clientWidth<=mobileWidth"/>
      <common-footer v-show="clientWidth>mobileWidth" :is-index="true"/>
    </div>
    <!-- </full-page> -->
    <slide-menu ref="slideMenu"/>
    <apply-dialog ref="applyDialog" :clientWidth="clientWidth"/>
  </div>
</template>

<script>
import CommonHeader from '@components/CommonHeader.vue';
import CommonFooter from '@components/CommonFooter.vue';
import MobileFooter from '@components/MobileFooter.vue';
import ApplyDialog from '@components/ApplyDialog.vue';
import BriefDesc from './components/BriefDesc.vue';
import Solution from './components/Solution.vue';
import System from './components/System.vue';
import Keqi from './components/Keqi.vue';
import Products from './components/Products.vue';
// import ApplyUse from './components/ApplyUse.vue';
import SlideMenu from '../../components/Menu.vue';

export default {
  name: 'Index',
  components: {
    CommonHeader,
    CommonFooter,
    MobileFooter,
    BriefDesc,
    Solution,
    System,
    Keqi,
    Products,
    // ApplyUse,
    SlideMenu,
    ApplyDialog,
  },
  data() {
    return {
      clientWidth: 1920,
      options: {
        // licenseKey: 'YOUR_KEY_HEERE',
        slidesNavigation: true,
      },
    };
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  mounted() {
    this.handleScroll();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleResize() {
      this.clientWidth = document.body.clientWidth;
      this.options.paddingTop = '50px';
    },
    openMobileMenu() {
      this.$refs.slideMenu.onOpen();
    },
    handleToApply() {
      this.$refs.applyDialog.openDialog();
    },
    handleScroll() {
      const scrollTop = window.pageYOffset
          || document.documentElement.scrollTop || document.body.scrollTop;
      console.log(scrollTop);
      const element1 = document.querySelector('.section1');
      const element2 = document.querySelector('.section2');
      const element3 = document.querySelector('.section3');
      const element4 = document.querySelector('.section4');
      const element5 = document.querySelector('.section5');
      const element6 = document.querySelector('.section6');
      if (this.clientWidth <= this.mobileWidth) {
        if (scrollTop >= 0 && scrollTop < 400) {
          element1.classList.add('active');
        }
        if (scrollTop >= 400 && scrollTop < 1000) {
          element2.classList.add('active');
        }
        if (scrollTop >= 1000 && scrollTop < 1700) {
          element3.classList.add('active');
        }
        if (scrollTop >= 1700 && scrollTop < 2300) {
          element4.classList.add('active');
        }
        if (scrollTop >= 2300 && scrollTop < 2800) {
          element5.classList.add('active');
        }
        if (scrollTop >= 2800 && scrollTop < 3300) {
          element6.classList.add('active');
        }
      } else {
        if (scrollTop >= 0 && scrollTop < 600) {
          element1.classList.add('active');
        }
        if (scrollTop >= 600 && scrollTop < 1380) {
          element2.classList.add('active');
        }
        if (scrollTop >= 1380 && scrollTop < 2100) {
          element3.classList.add('active');
        }
        if (scrollTop >= 2100 && scrollTop < 2780) {
          element4.classList.add('active');
        }
        if (scrollTop >= 2780 && scrollTop < 3100) {
          element5.classList.add('active');
        }
        if (scrollTop >= 2800 && scrollTop < 3100) {
          element6.classList.add('active');
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.index-container {
  .fp-auto-height {
    padding: 0 !important;
  }

  .section1.active {
    /deep/ .bg-info {
      .sys-title {
        animation: slideInDown 1s 1;
      }

      .desc {
        animation: slideInDown 1.5s 1;
      }
    }
  }

  .section2.active {
    /deep/ .solution-wapper {
      .title {
        &::after {
          transition: all 2s cubic-bezier(0.55, 0.09, 0.68, 0.53);
          width: 120px;
        }
      }

      .content-item {
        p {
          animation: fadeInUp 2s 1;
          opacity: 1;
        }
      }
    }

    /deep/ .mobile-solution-wapper {
      .title {
        &::after {
          width: 60px;
        }
      }
    }
  }

  .section3.active {
    /deep/ .system-content, /deep/ .mobile-system-content {
      .peitu {
        animation: fadeinR 2s 1;
        opacity: 1;
      }
    }
  }

  .section4.active {
    /deep/ .keqi-content, /deep/ .mobile-keqi-content {
      .peitu {
        animation: fadeinL 2s 1;
        opacity: 1;
      }
    }
  }

  .section5.active {
    /deep/ .product-center {
      overflow: hidden;

      .title {
        animation: flipInX 2s 1;
        opacity: 1;
      }
    }

    /deep/ .mobile-product-center {
      .title {
        animation: none;
      }

      .first-item-content {
        .item {
          opacity: 1;

          &:first-child {
            animation: slideInRight 1s 1;
          }

          &:nth-child(2) {
            animation: slideInRight 1.2s 1;
            animation-delay: 0.1s;
          }

          &:nth-child(3) {
            animation: slideInRight 2s 1;
            animation-delay: 0.2s;
          }
        }
      }

      .last-item-content {
        .item {
          opacity: 1;

          &:first-child {
            animation: slideInRight 1.4s 1;
            animation-delay: 0.2s;
          }

          &:nth-child(2) {
            animation: slideInRight 1.8s 1;
            animation-delay: 0.1s;
          }

          &:nth-child(3) {
            animation: slideInRight 2s 1;
            animation-delay: 0.2s;
          }
        }
      }
    }
  }

  .section6.active {
    /deep/ .mobile-bg {
      .sign-title {
        animation: swing 2s 1;
      }
    }
  }
}

@keyframes fadeinR {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeinL {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

.no-fullpage {
  .section {
    height: 100vh;
    min-height: 700px;
  }

  .fp-auto-height {
    height: auto;
    min-height: 0;
  }

  .section5 {
    height: auto;
    min-height: 0;
  }
}

@import "../../static/fullpage.min.css";
@import "../../less/mobileIndex.less";
</style>
