<template>
  <div :class="['solution-wapper',clientWidth>mobileWidth?'':'mobile-solution-wapper']">
    <p class="title">广烁国产化签章产品解决方案</p>
    <p class="desc">国产化电子签章应用解决方案，全方位支持国产化系统中的电子签章应用</p>
    <div :class="[clientWidth>mobileWidth?'content':'mobile-content']">
      <div class="content-item" @click="toDetail('introduce')">
        <div class="img-wapper">
          <img src="../../../assets/imgs/index/pic_chanpinjieshao@2x.png"/>
        </div>
        <p>产品介绍</p>
      </div>
      <div class="content-item" @click="toDetail('chara')">
        <div class="img-wapper">
          <img src="../../../assets/imgs/index/pic_chanpintedian@2x.png"/>
        </div>
        <p>产品特点</p>
      </div>
      <div class="content-item" @click="toDetail('adapt')">
        <div class="img-wapper">
          <img src="../../../assets/imgs/index/pic_shipeichanpin@2x.png"/>
        </div>
        <p>适配产品</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Solution',
  data() {
    return {};
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    toDetail(anchorName) {
      this.$router.push(`/LocalizationPlan?anchor=${anchorName}`);
    },
  },
};

</script>

<style lang="less" scoped>
.solution-wapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;

  p {
    margin: 0;
  }

  .title {
    font-size: 36px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    position: relative;
    padding-bottom: 56px;

    &::after {
      position: absolute;
      content: "";
      // width: 120px;
      width: 0;
      height: 8px;
      background: #F75959;
      border-radius: 4px;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .desc {
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    margin-bottom: 60px;
  }

  .content {
    display: flex;
    justify-content: center;

    .content-item {
      width: 360px;
      height: 430px;
      background: #FFFFFF;
      box-shadow: 0 10px 20px 0 rgba(153, 153, 153, 0.4);
      margin-right: 30px;
      position: relative;
      cursor: pointer;

      .img-wapper {
        width: 234px;
        height: 206px;
        line-height: 206px;
        margin: 100px auto 0;
        text-align: center;

        img {
          max-width: 100%;
          max-height: 100%;
          height: auto;
          width: auto;
        }
      }

      p {
        position: absolute;
        bottom: 100px;
        width: 100%;
        text-align: center;
        font-size: 20px;
        color: #000;
      }
    }
  }

  .mobile-content {
    margin: 16px 38px 0;

    .content-item {
      display: flex;
      align-items: center;
      height: 108px;
      background: #FFFFFF;
      box-shadow: 0px 5px 10px 0px rgba(153, 153, 153, 0.4);
      margin-bottom: 10px;
      padding-left: 25px;

      .img-wapper {
        width: 98px;
        height: 75px;
        line-height: 75px;
        margin-right: 25px;
        text-align: center;

        img {
          max-width: 100%;
          max-height: 100%;
          height: auto;
          width: auto;
        }
      }

      p {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 22px;
        opacity: 0;
      }
    }
  }
}
</style>
